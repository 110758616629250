<template>
	<div>
		<TableList 
            :form_data_seo.sync="form_data_seo" 
            :columns="columns" 
            :scroll="{x: true}" 
            :get_table_list="this.get_table_list" 
            :is_page="true" 
            ref="list" 
            rowKey="id" 
            :submit_preprocessing="submit_preprocessing"
        >
			<template slot="right_btn">
				<!-- <export-table v-if="this.$store.state.page_auth.export" url="/manage/crm.report/orderScheduleExport"></export-table> -->
                <a-popconfirm title="您确定要导出该报表吗？" ok-text="确认" cancel-text="取消" @confirm="export_excel">
					<a-button class="a_btn">导出</a-button>
				</a-popconfirm>
			</template>
		</TableList>
	</div>
</template>

<script>
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
    import {orderSchedule,orderScheduleExport} from "@/api/advance";

	const columns = [
		{
			title: "姓名",
			dataIndex: "username",
			scopedSlots: {
				customRender: "username"
			}
		},
		{
			title: "部门",
			dataIndex: "department_name",
			scopedSlots: {
				customRender: "department_name"
			}
		},
        {
			title: "下单客户数",
			dataIndex: "customer_num",
			scopedSlots: {
				customRender: "customer_num"
			}
		},
        {
			title: "下单商品数",
			dataIndex: "goods_num",
			scopedSlots: {
				customRender: "goods_num"
			}
		},
        {
			title: "下单金额",
			dataIndex: "pay_price",
			scopedSlots: {
				customRender: "pay_price"
			}
		},
        {
			title: "出库客户数",
			dataIndex: "checkout_customer_num",
			scopedSlots: {
				customRender: "checkout_customer_num"
			}
		},
        {
			title: "出库商品数",
			dataIndex: "checkout_goods_num",
			scopedSlots: {
				customRender: "checkout_goods_num"
			}
		},
        {
			title: "出库金额",
			dataIndex: "checkout_pay_price",
			scopedSlots: {
				customRender: "checkout_pay_price"
			}
		},
        {
			title: "待出库客户数",
			dataIndex: "wait_checkout_customer_num",
			scopedSlots: {
				customRender: "wait_checkout_customer_num"
			}
		},
        {
			title: "待出库商品数",
			dataIndex: "wait_checkout_goods_num",
			scopedSlots: {
				customRender: "wait_checkout_goods_num"
			}
		},
        {
			title: "待出库商品金额",
			dataIndex: "wait_checkout_pay_price",
			scopedSlots: {
				customRender: "wait_checkout_pay_price"
			}
		},
	];

	export default {
		components: {
			Form,
			TableList
		},
		data() {
			return {
				columns,
				page: 1,
				pagination: {
					...this.$config.pagination
				},
				loading: false,
				list: [],
                get_table_list: orderSchedule,

                submit_preprocessing: {
                    array_to_string: ['department']
                },
				
                form_data_seo: {
                    ...this.$config.form_data_seo,
                    list: [
                        {
                            type: "tree-select",
                            name: "department",
                            title: "部门",
                            options: {},
                            treeData: [],
                            multiple: true
                        },
                        {
                            type: "text",
                            name: "keyword",
                            title: "姓名",
                            placeholder: '姓名/账号/工号/手机号',
                            mode: "default",
                            options: {},
                            list: []
                        },
                        {
                            type: "range_date",
                            name: "range_date",
                            title: "日期",
                           options: {
                           	initialValue: [this.$moment(new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000)), 'YYYY/MM/DD'), this.$moment(new Date(), 'YYYY/MM/DD')],
                           },
                            start: {
                                name: 'start_time'
                            },
                            end: {
                                name: 'end_time'
                            },
                        },
                    ],
                },
				form_data: {}
			};
		},
		async created() {
			this.$method.get_department().then(res => {
                this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department', 'treeData', res)
            });
		},
		methods: {
			export_excel(){
                orderScheduleExport({
                    data:{
                        ...this.$refs.list.seo_data, // 筛选项表单值
                    },
                    info:true,
                    loading:true
                })
            },
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>
