import req from "../methods/req"
import method from '../methods/method'

// 预付款订单提货进度统计报表
export const orderSchedule = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.report/orderSchedule',
        data
    })
}

// 导出Excel
export const orderScheduleExport = function (data) {
	return req({
		url: '/manage/crm.report/orderScheduleExport',
		...data,
	})
}

